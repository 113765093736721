import { Routes } from '@angular/router';
import { AuthLayout } from '../layouts/auth/auth.layout.js';

export const AuthRouting: Routes = [
  {
    path: 'login',
    component: AuthLayout,
    title: 'Đăng nhập',
    children: [
      {
        path: '',
        loadComponent: () =>
          // (globalThis as any)['isMobile']
          window.innerWidth <= 768 // monkey patch, force to use mobile version on landing page
            ? import('./login-register-mobile/login-register-mobile.page.js').then((c) => c.LoginRegisterMobilePage)
            : import('./login-register-desktop/login-register-desktop.page.js').then((c) => c.LoginRegisterDesktopPage),
      },
    ],
  },
  {
    path: 'forgot',
    title: 'Quên mật khẩu',
    loadComponent: () =>
      (globalThis as any)['isMobile']
        ? import('./forgot-mobile/forgot-mobile.page.js').then((c) => c.ForgotMobilePage)
        : import('./forgot-desktop/forgot-desktop.page.js').then((c) => c.ForgotDesktopPage),
    data: { mode: 'forgot' },
  },
  {
    path: 'reset',
    title: 'Xóa mật khẩu',
    loadComponent: () =>
      (globalThis as any)['isMobile']
        ? import('./forgot-mobile/forgot-mobile.page.js').then((c) => c.ForgotMobilePage)
        : import('./forgot-desktop/forgot-desktop.page.js').then((c) => c.ForgotDesktopPage),
    data: { mode: 'forgot' },
  },
  {
    path: 'active',
    title: 'Kích hoạt',
    loadComponent: () =>
      (globalThis as any)['isMobile']
        ? import('./forgot-mobile/forgot-mobile.page.js').then((c) => c.ForgotMobilePage)
        : import('./forgot-desktop/forgot-desktop.page.js').then((c) => c.ForgotDesktopPage),
    data: { mode: 'active' },
  },
  {
    path: 'new-password',
    title: 'Tạo mật khẩu mới',
    loadComponent: () =>
      (globalThis as any)['isMobile']
        ? import('./new-password-mobile/new-password-mobile.page.js').then((c) => c.NewPasswordMobilePage)
        : import('./new-password-desktop/new-password-desktop.page.js').then((c) => c.NewPasswordDesktopPage),
  },
  {
    path: 'register',
    title: 'Đăng ký',
    loadComponent: () =>
      // (globalThis as any)['isMobile']
      window.innerWidth <= 768 // monkey patch, force to use mobile version on landing page
        ? import('./register-complete-mobile/register-complete-mobile.page.js').then((c) => c.RegisterCompleteMobilePage)
        : import('./register-complete-desktop/register-complete-desktop.page.js').then((c) => c.RegisterCompleteDesktopPage),
  },
];
