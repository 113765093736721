import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SettingStore, SettingState } from './setting.store.js';

@Injectable({ providedIn: 'root' })
export class SettingQuery extends Query<SettingState> {
  constructor(protected override store: SettingStore) {
    super(store);
  }
}
