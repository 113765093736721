import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { domainEnvironment } from '@awread/global/environments';
import { UniversalService } from '@awread/kernel/services';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoginGuard {
  constructor(
    private router: Router,
    private universalService: UniversalService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.universalService.isBrowser()) {
      if (localStorage.getItem('accessToken')) {
        console.log('login guard');
        return of(true);
      }
      if (domainEnvironment.domain === 'writer') {
        this.router.navigate(['/landing']);
      } else if (domainEnvironment.domain === 'console') {
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/']);
      }
      return of(false);
    } else {
      return of(true);
    }
  }
}
