import { provideRouter, Routes, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { NotFoundRouting } from '@awread/global/packages';
import { AuthRouting } from '@awread/shared/ui-auth';
import { SingleRouting } from '@awread/reader/web/ui-single';
import { MarketRouting } from '@awread/reader/web/ui-market';
import { AuthorRouting } from '@awread/reader/web/ui-author';
import { PayingRouting } from '@awread/reader/web/ui-paying';
import { EventRouting } from '@awread/reader/web/ui-event';
import { globalProvider } from '@awread/global/settings';

const routes: Routes = [
  ...AuthRouting,
  {
    path: '',
    loadComponent: () =>
      (globalThis as any).isMobile
        ? import('../layouts/shared-mobile/shared-mobile.layout.js').then((c) => c.SharedMobileLayout)
        : import('../layouts/shared-desktop/shared-desktop.layout.js').then((c) => c.SharedDesktopLayout),
    // component: SharedCommonLayout,
    children: [...MarketRouting, ...NotFoundRouting, ...SingleRouting, ...AuthorRouting, ...PayingRouting, ...EventRouting],
  },
  { path: '**', pathMatch: 'full', redirectTo: 'not-found' },
];

export const ReaderWebSharedProviders = [
  provideRouter(
    routes,
    withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
    withEnabledBlockingInitialNavigation(),
  ),
  ...globalProvider,
];
