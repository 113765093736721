import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DynamicTabLayoutOrgan } from '@awread/global/design-system';
import { faUserCircle, faBookOpen, faBookmark, faClock, faBook } from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'awread-single',
  imports: [DynamicTabLayoutOrgan],
  templateUrl: './single.layout.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleLayout implements OnInit {
  tabs: Record<string, any> = {
    // profile: {
    //   icon: faUserCircle,
    //   title: 'Hồ sơ của tôi',
    //   tabId: 'profile',
    //   image: '',
    // },
    library: {
      icon: faBookOpen,
      title: 'Thư viện',
      tabId: 'library',
      image: '',
    },
    'buyed-book': {
      icon: faBook,
      title: 'Truyện đã mua',
      tabId: 'buyed-book',
      image: '',
    },
    bookmark: {
      icon: faBookmark,
      title: 'Truyện đã lưu',
      tabId: 'bookmark',
      image: '',
    },
    history: {
      icon: faClock,
      title: 'Lịch sử đọc',
      tabId: 'history',
      image: '',
    },
  };
  constructor() {}

  ngOnInit(): void {}
}
