import { Routes } from '@angular/router';
import { AuthorLayout } from '../layouts/author/author.layout.js';

export const AuthorRouting: Routes = [
  {
    path: 'a',
    component: AuthorLayout,
    children: [
      {
        path: ':username',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./author-mobile/author-mobile.page.js').then((c) => c.AuthorMobilePage)
            : import('./author-desktop/author-desktop.page.js').then((c) => c.AuthorDesktopPage),
      },

    ],
  },
];
