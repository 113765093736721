import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { MetaTagService } from '@awread/kernel/services';

@Injectable({ providedIn: 'root' })
export class AppTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title, private metaService: MetaTagService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    let titleName = this.buildTitle(routerState);
    if (titleName !== undefined) {
      titleName = `${titleName} | Awread`;
    } else {
      // set default title here
    }
    this.metaService.autoSetMetaTagOnRouteEnd(routerState.url, titleName);
  }
}
