import { Routes } from '@angular/router';
import { MarketLayout } from '../layouts/market/market.layout.js';

export const MarketRouting: Routes = [
  {
    path: '',
    component: MarketLayout,

    children: [
      {
        path: '',
        loadComponent: () => {
          // console.log('MarketRouting isMobile', (globalThis as any)['isMobile']);
          return (globalThis as any)['isMobile']
            ? import('./home-mobile/home-mobile.page.js').then((c) => c.HomeMobilePage)
            : import('./home-desktop/home-desktop.page.js').then((c) => c.HomeDesktopPage);
        },
        data: {
          reuseComponent: true,
        },
      },
      {
        path: 'l/:routeName',
        title: 'Tất cả truyện',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./list-books-mobile/list-books-mobile.page.js').then((c) => c.ListBooksMobilePage)
            : import('./list-books-desktop/list-books-desktop.page.js').then((c) => c.ListBooksDesktopPage),
        data: {
          // NOTE: not use, cause error infinity scroll
          // reuseComponent: true,
        },
      },
      {
        path: 'b/:bookNid/read',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./read-mobile/read-mobile.page.js').then((c) => c.ReadMobilePage)
            : import('./read-desktop/read-desktop.page.js').then((c) => c.ReadDesktopPage),
        data: {
          // NOTE: cause reading count error
          // reuseComponent: true,
        },
      },

      {
        path: 'b/:bookNid',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./detail-mobile/detail-mobile.page.js').then((c) => c.DetailMobilePage)
            : import('./detail-desktop/detail-desktop.page.js').then((c) => c.DetailDesktopPage),
        data: {
          reuseComponent: true,
        },
      },
      {
        path: 'library/:bookNid',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./detail-mobile/detail-mobile.page.js').then((c) => c.DetailMobilePage)
            : import('./detail-desktop/detail-desktop.page.js').then((c) => c.DetailDesktopPage),
        data: {
          reuseComponent: true,
        },
      },
      {
        path: 'introduction',
        title: 'Awread là gì? Giới thiệu về Awread',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./introduction-mobile/introduction-mobile.page.js').then((c) => c.IntroductionMobilePage)
            : import('./introduction-desktop/introduction-desktop.page.js').then((c) => c.IntroductionDesktopPage),
      },

      {
        path: 'blogs',
        title: 'tin tức',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./news-mobile/news-mobile.page.js').then((c) => c.NewsMobilePage)
            : import('./news-desktop/news-desktop.page.js').then((c) => c.NewsDesktopPage),
        data: {
          reuseComponent: true,
        },
      },
      {
        path: 'blogs/:chapterNid',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./news-detail-mobile/news-detail-mobile.page.js').then((c) => c.NewsDetailMobilePage)
            : import('./news-detail-desktop/news-detail-desktop.page.js').then((c) => c.NewsDetailDesktopPage),
      },

      {
        path: 'terms-service',
        title: 'điều khoản dịch vụ',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./terms-service-mobile/terms-service-mobile.page.js').then((c) => c.TermsServiceMobilePage)
            : import('./terms-service-desktop/terms-service-desktop.page.js').then((c) => c.TermsServiceDesktopPage),
      },
      {
        path: 'question-and-answer',
        title: 'hỏi đáp',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./question-and-answer-mobile/question-and-answer-mobile.page.js').then((c) => c.QuestionAndAnswerMobilePage)
            : import('./question-and-answer-desktop/question-and-answer-desktop.page.js').then((c) => c.QuestionAndAnswerDesktopPage),
      },
      // {
      //   path: 'cart',
      //   title: 'giỏ hàng',
      //   loadComponent: () =>
      //     (globalThis as any)['isMobile']
      //       ? import('./cart-mobile/cart-mobile.page.js').then((c) => c.CartMobilePage)
      //       : import('./cart-desktop/cart-desktop.page.js').then((c) => c.CartDesktopPage),
      // },
      {
        path: 'genres',
        title: 'Thể loại',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./list-genres-or-tags-mobile/list-genres-or-tags-mobile.page.js').then((c) => c.ListGenresOrTagsMobilePage)
            : import('./list-genres-or-tags-desktop/list-genres-or-tags-desktop.page.js').then((c) => c.ListGenresOrTagsDesktopPage),
      },
      {
        path: 'tags',
        title: 'Thẻ',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./list-genres-or-tags-mobile/list-genres-or-tags-mobile.page.js').then((c) => c.ListGenresOrTagsMobilePage)
            : import('./list-genres-or-tags-desktop/list-genres-or-tags-desktop.page.js').then((c) => c.ListGenresOrTagsDesktopPage),
      },
    ],
  },
];
