import { Routes } from '@angular/router';
import { PayingLayout } from '../layouts/paying/paying.layout.js';

export const PayingRouting: Routes = [
  {
    path: 'paying',
    component: PayingLayout,
    children: [
      {
        path: 'quest',
        title: 'Điểm danh nhận coin',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./quest-mobile/quest-mobile.page.js').then((c) => c.QuestMobilePage)
            : import('./quest-desktop/quest-desktop.page.js').then((c) => c.QuestDesktopPage),
      },
      {
        path: 'coin',
        title: 'Mua coin',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./rd-user-buy-coin-mobile/rd-user-buy-coin-mobile.page.js').then((c) => c.RdUserBuyCoinMobilePage)
            : import('./rd-user-buy-coin-desktop/rd-user-buy-coin-desktop.page.js').then((c) => c.RdUserBuyCoinDesktopPage),
      },
      {
        path: 'payment-history',
        title: 'Lịch sử mua coin',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./rd-history-payment-mobile/rd-history-payment-mobile.page.js').then((c) => c.RdHistoryPaymentMobilePage)
            : import('./rd-history-payment-desktop/rd-history-payment-desktop.page.js').then((c) => c.RdHistoryPaymentDesktopPage),
      },
      {
        path: 'donate-history',
        title: 'Lịch sử tặng coin',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./rd-history-donate-mobile/rd-history-donate-mobile.page.js').then((c) => c.RdHistoryDonateMobilePage)
            : import('./rd-history-donate-desktop/rd-history-donate-desktop.page.js').then((c) => c.RdHistoryDonateDesktopPage),
      },
    ],
  },
];
