import { Routes } from '@angular/router';
import { SingleLayout } from '../layouts/single/single.layout.js';
import { LoginGuard } from '@awread/global/guards';

export const SingleRouting: Routes = [
  {
    path: 'single',
    component: SingleLayout,
    children: [
      {
        canActivate: [LoginGuard],
        path: 'profile',
        title: 'Hồ sơ',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./profile-mobile/profile-mobile.page.js').then((c) => c.ProfileMobilePage)
            : import('./profile-desktop/profile-desktop.page.js').then((c) => c.ProfileDesktopPage),
      },


      {
        path: 'docs',
        title: 'Tài liệu',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./docs-mobile/docs-mobile.page.js').then((c) => c.DocsMobilePage)
            : import('./docs-desktop/docs-desktop.page.js').then((c) => c.DocsDesktopPage),
      },
      {
        canActivate: [LoginGuard],
        path: 'history',
        title: 'Lịch sử',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./history-mobile/history-mobile.page.js').then((c) => c.HistoryMobilePage)
            : import('./history-desktop/history-desktop.page.js').then((c) => c.HistoryDesktopPage),
      },
      {
        canActivate: [LoginGuard],
        path: 'bookmark',
        title: 'Đánh dấu',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./bookmark-mobile/bookmark-mobile.page.js').then((c) => c.BookmarkMobilePage)
            : import('./bookmark-desktop/bookmark-desktop.page.js').then((c) => c.BookmarkDesktopPage),
      },
      {
        canActivate: [LoginGuard],
        path: 'library',
        title: 'Thư viện',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./library-mobile/library-mobile.page.js').then((c) => c.LibraryMobilePage)
            : import('./library-desktop/library-desktop.page.js').then((c) => c.LibraryDesktopPage),
      },
      {
        canActivate: [LoginGuard],
        path: 'buyed-book',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./buyed-books-mobile/buyed-books-mobile.page').then((m) => m.BuyedBooksMobilePage)
            : import('./buyed-books-desktop/buyed-books-desktop.page').then((m) => m.BuyedBooksDesktopPage),
      },
      {
        canActivate: [LoginGuard],
        path: 'notification',
        title: 'Thông báo',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./notification-mobile/notification-mobile.page.js').then((c) => c.NotificationMobilePage)
            : import('./notification-desktop/notification-desktop.page.js').then((c) => c.NotificationDesktopPage),
      },
    ],
  },
  {
    path: 'install',
    title: 'Cài đặt lối tắt đến Awread',
    loadComponent: () =>
      (globalThis as any)['isMobile']
        ? import('./install-mobile/install-mobile.page').then((m) => m.InstallMobilePage)
        : import('./install-desktop/install-desktop.page').then((m) => m.InstallDesktopPage),
  },
];
