import { Injectable } from '@angular/core';
import { HeathApi } from '../apis/index.js';
import { SettingGear } from '../gears/index.js';
import { SettingQuery } from '../states/setting/index.js';

@Injectable({ providedIn: 'root' })
export class SettingFacade {
  setting$ = this.settingQuery.select();
  storage$ = this.settingQuery.select((state) => state.storage);
  constructor(private settingGear: SettingGear, private settingQuery: SettingQuery, private heathApi: HeathApi) {}

  checkHeath() {
    this.heathApi.checkConnection();
  }
}
