import { Component, HostBinding, HostListener, inject, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { domainEnvironment } from '@awread/global/environments';
import { UniversalService } from '@awread/kernel/services';
import { ReaderStarter } from '@awread/global/starters';
import { ToastComponent } from '@awread/global/packages';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastComponent],
  template: `
    <app-toast></app-toast>
    
      <router-outlet></router-outlet>
    
  `,
  styleUrls: [],
})
export class AppComponent implements OnDestroy {
  title = 'reader-web';
  // @HostBinding('attr.class') hostThemeClass = 'dark';
  @HostBinding('attr.app-version') appVersionAttr = domainEnvironment.appVersion;
  constructor(universalService: UniversalService, readerStarter: ReaderStarter) {
    if (universalService.isBrowser()) {
      readerStarter.start();
    }
  }

  ngOnDestroy() {
    // console.warn('appComponent destroyed!');
  }
}
