import { ViewportScroller, Location, CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { RouterModule } from '@angular/router';
@Component({
  standalone: true,
  selector: 'awread-market',
  imports: [CommonModule, RouterModule],
  templateUrl: './market.layout.html',
  styles: [
    `
      :host {
        display: block;
      }

      .animation {
        transition: all 0.1s linear;
        /* background: red; */
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketLayout {}
