import { Routes } from '@angular/router';
import { EventLayout } from '../layouts/event/event.layout.js';

export const EventRouting: Routes = [
  {
    path: '',
    component: EventLayout,
    children: [
      {
        path: 'invite-friend',
        title: 'mời bạn bè',
        loadComponent: () =>
          (globalThis as any)['isMobile']
            ? import('./invite-friend-mobile/invite-friend-mobile.page.js').then((c) => c.InviteFriendMobilePage)
            : import('./invite-friend-desktop/invite-friend-desktop.page.js').then((c) => c.InviteFriendDesktopPage),
      },
    ],
  },
];
