import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SettingApi {
  constructor(private apollo: Apollo) {}

  get() {
    return of();
  }
}
