import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SnackbarService } from '@awread/global/packages';

@Injectable({ providedIn: 'root' })
export class HeathApi {
  constructor(private httpClient: HttpClient, private snackbarService: SnackbarService) {}

  checkConnection() {
    this.httpClient.get(`/api/check`).subscribe({
      next: (res) => {
        // this.snackbarService.showSuccess('Đã kết nối api');
      },
      error: () => {
        this.snackbarService.showWarning('API: Không có kết nối mạng');
      },
      complete: () => {},
    });
  }
}
