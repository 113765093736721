import { APP_ID, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { ApolloModule } from 'apollo-angular';
import { HttpClientModule, provideHttpClient } from '@angular/common/http';
import { apolloProvider } from '@awread/global/apollo';
import { environment } from '@awread/global/environments';
import { MetaTagService, ReuseStrategyService } from '@awread/kernel/services';
import { AppTitleStrategy } from './app-title.strategy.js';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';
import { provideDialogConfig } from '@ngneat/dialog';
import localeVi from '@angular/common/locales/vi';
import { registerLocaleData } from '@angular/common';

// registerLocaleData(localeVi);

export const globalProvider = [
  // { provide: LOCALE_ID, useValue: 'vi' },
  environment.production ? [] : importProvidersFrom(AkitaNgDevtools.forRoot()),
  provideDialogConfig({
    windowClass: 'bg-base-100',
    sizes: {
      // sm: {
      //   minHeight: '360px',
      // },
      // md: {
      //   minHeight: '720px',
      // },
      // lg: {
      //   minHeight: '1080px',
      // },
      fullscreen: {
        height: '100vh',
        width: '100vw',
      },
    },
  }),
  importProvidersFrom(ApolloModule),
  provideHttpClient(),
  MetaTagService,
  apolloProvider,
  { provide: TitleStrategy, useClass: AppTitleStrategy },
  { provide: RouteReuseStrategy, useClass: ReuseStrategyService },
];
